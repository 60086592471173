import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import CountryFlag from '@globalfishingwatch/ui-components/dist/countryflag'
import { DATE_FORMAT_LONG } from '../constants'
import { ReactComponent as IconTarget } from '../icons/target.svg'
import styles from './Event.module.css'
import { EVENT_TYPES_IDS } from '../constants.js'

class Event extends Component {
  onEventHover = () => {
    const { data, onEventHover } = this.props
    onEventHover(data.id, data.type)
  }

  onEncounterClick = () => {
    const { data, onEncounterClick, selectedEventId } = this.props
    if (data.id === selectedEventId) {
      onEncounterClick()
    } else {
      onEncounterClick(data.id)
    }
  }

  renderVesselLink = (id, name, iso) => {
    const { onVesselClick } = this.props
    return (
      <Fragment>
        <CountryFlag iso={iso} />
        <button className={styles.vesselName} onClick={() => onVesselClick(id)}>
          {name}
        </button>
      </Fragment>
    )
  }

  getVesselData = (event, isPrimary) => {
    const vesselData = isPrimary ? event.vessel : event.encounter.vessel

    if (!vesselData) {
      console.warn('missing vessel fields on', event)
      return {}
    }

    if (vesselData.name === null || vesselData.name === undefined) {
      if (vesselData.ssvid !== undefined) {
        vesselData.name = vesselData.ssvid
      } else {
        vesselData.name = vesselData.id ? `${vesselData.id.substr(0, 10)}...` : ''
      }
    }
    return vesselData
  }

  onEventTargetClick = () => {
    this.props.onEventTargetClick(this.props.data)
  }

  render() {
    const { data, selectedEventId, isHighlighted, isSecondary, showPrimaryVessel } = this.props
    const start = dayjs(data.start)
    const formattedDuration = data.end ? start.from(dayjs(data.end), true) : 'unknown'
    const formattedDate = start.format(DATE_FORMAT_LONG)
    const iso = 'IDN'
    const className = cx(styles.Event, {
      [styles.highlighted]: isHighlighted,
      [styles.secondary]: isSecondary,
    })

    const mainVesselData = this.getVesselData(data, true)

    let title = null
    if (data.type === EVENT_TYPES_IDS.ENCOUNTER) {
      const encounteredVesselData = this.getVesselData(data, false)

      const encounteredVessel = encounteredVesselData
        ? this.renderVesselLink(encounteredVesselData.id, encounteredVesselData.name, iso)
        : 'unknown'
      if (showPrimaryVessel === true) {
        title = (
          <Fragment>
            Vessels
            <br />
            {this.renderVesselLink(mainVesselData.id, mainVesselData.name, iso)}
            <br />
            {encounteredVessel}
          </Fragment>
        )
      } else {
        title = (
          <Fragment>
            Encountered vessel <br />
            {encounteredVessel}
          </Fragment>
        )
      }
    } else if (showPrimaryVessel === true) {
      title = this.renderVesselLink(mainVesselData.id, mainVesselData.name, iso)
    }

    return (
      <div className={className} onMouseEnter={this.onEventHover}>
        {title}
        <button className={styles.targetBtn} onClick={this.onEventTargetClick}>
          <IconTarget />
        </button>
        {data.type === EVENT_TYPES_IDS.PORT && (
          <div className={styles.twoColumns}>
            <div className={styles.column}>
              <span className={styles.label}>Port</span>
              <span className={styles.data}>
                {data.port.flag !== null && <CountryFlag iso={data.port.flag} />}
                {data.port.name === null ? 'Unknown port' : data.port.name}
              </span>
            </div>
          </div>
        )}
        <div className={styles.twoColumns}>
          <div className={styles.column}>
            <span className={styles.label}>Date</span>
            <span className={styles.data}>{formattedDate}</span>
          </div>
          <div className={styles.column}>
            <span className={styles.label}>Duration</span>
            <span className={styles.data}>{formattedDuration}</span>
          </div>
        </div>
        {data.type === EVENT_TYPES_IDS.ENCOUNTER && (
          <Fragment>
            <div className={styles.twoColumns}>
              <div className={styles.column}>
                <span className={styles.label}>Median Distance</span>
                <span className={styles.data}>{data.medianDistanceKilometers} kms</span>
              </div>
              <div className={styles.column}>
                <span className={styles.label}>Median Speed</span>
                <span className={styles.data}>{data.medianSpeedKnots} knots</span>
              </div>
            </div>
            {!showPrimaryVessel && (
              <button className={styles.encounterBtn} onClick={this.onEncounterClick}>
                {selectedEventId === data.id
                  ? 'Hide encountered vessel track'
                  : 'Show encountered vessel track'}
              </button>
            )}
          </Fragment>
        )}
        {data.type === EVENT_TYPES_IDS.GAP && (
          <div className={styles.twoColumns}>
            <div className={styles.column}>
              <span className={styles.label}>Distance from shore</span>
              <span className={styles.data}>{data.distanceFromShoreMeters} m</span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Event
