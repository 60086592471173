import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import dayjs from 'dayjs'
import CountryFlag from '@globalfishingwatch/ui-components/dist/countryflag'
import { DATE_FORMAT } from '../constants'
import Loader from '../loader/Loader'
import styles from './Vessels.module.css'

const VesselTitle = ({ vesselName, searchQuery }) => {
  if (!vesselName) return '(name unknown)'
  const fragments = vesselName.replace(new RegExp(searchQuery, 'gi'), `<mark>${searchQuery}</mark>`)
  return <span dangerouslySetInnerHTML={{ __html: fragments }} /> // eslint-disable-line
}

VesselTitle.propTypes = {
  vesselName: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
}

class Vessels extends Component {
  constructor(props) {
    super(props)
    this.offset = 180
  }

  componentDidMount() {
    this.container.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const hasReachEnd =
      this.container.scrollHeight - this.container.scrollTop === this.container.clientHeight
    const { hasMorePages, searchPage, updateListPage } = this.props
    if (hasReachEnd === true && hasMorePages === true) {
      updateListPage(searchPage + 1)
    }
  }

  render() {
    const { loading, loaded, vessels, onVesselClick, hasMorePages, searchQuery } = this.props
    const hasVessels = vessels && vessels.entries && vessels.entries.length > 0
    let content = null
    if (!hasVessels && loaded) {
      content = (
        <div className={styles.resultItemDisabled}>
          <span>There were no results for the given search</span>
        </div>
      )
    } else {
      content = (
        <Fragment>
          <ul className={styles.resultsList}>
            {vessels.entries.map((vessel, i) => (
              <li
                className={styles.resultItem}
                key={vessel.vesselId || i}
                onClick={() => onVesselClick(vessel.vesselId)} // TODO: Improve a11y
              >
                <CountryFlag iso="IDN" />
                <span className={styles.name}>
                  <VesselTitle vesselName={vessel.name} searchQuery={searchQuery} />
                </span>
                <span className={styles.subsection}>SSVID: {vessel.ssvid}</span>
                <span className={styles.subsection}>
                  First transmission: {dayjs(vessel.start).format(DATE_FORMAT)}
                </span>
                <span className={styles.subsection}>
                  Last transmission: {dayjs(vessel.end).format(DATE_FORMAT)}
                </span>
              </li>
            ))}
          </ul>
          {loading === false && hasMorePages === false && (
            <span className={cx(styles.resultItem, styles.resultItemDisabled)}>
              Found a total of {vessels.entries.length} items.
            </span>
          )}
        </Fragment>
      )
    }
    return (
      <div
        ref={(el) => {
          this.container = el
        }}
        className={styles.container}
      >
        {content}
        {loading && <Loader />}
      </div>
    )
  }
}

Vessels.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  searchPage: PropTypes.number,
  hasMorePages: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  updateListPage: PropTypes.func.isRequired,
  vessels: PropTypes.shape({
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        vesselId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  onVesselClick: PropTypes.func.isRequired,
}

Vessels.defaultProps = {
  searchPage: 0,
}

export default Vessels
